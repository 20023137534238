.no-company-text {
  color: #989eb1;
  font-size: 12px;
  line-height: 1.3;
}

.sign-btn {
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(204, 0, 51) 0%,
    rgb(146, 0, 36) 100%
  );
  border: none !important;
  border-radius: 10px;
  padding: 15px 110px 15px 110px;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
}

.sc-btn {
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(204, 0, 51) 0%,
    rgb(146, 0, 36) 100%
  );
  border: none !important;
  border-radius: 10px;
  padding: 15px 0px;
  width: 300px;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
}

.form-label-success {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
}

.form-check {
  margin-top: 7%;
}

.form-check-input {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #9c29b7 !important;
  border-color: #9c29b7 !important;
}

.sign-label {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  margin-top: 2%;
}

.signin-link {
  color: #ffffff !important;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none !important;
}

.sign-input {
  border-radius: 0px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 0px !important;
  border-bottom: 1px solid #ced4da;
}

.signin-btn-row {
  margin-top: 5%;
  margin-bottom: 5%;
}

.bottom {
  margin-bottom: 10%;
}

.signup-logo {
  margin-top: 10%;
  margin-bottom: 5%;
  width: auto;
  height: 50px;
}

.upload-photo-page {
  overflow: hidden !important;
}

.upload-photo-col {
  width: 300px;
  margin-top: 15%;
}

.profile-img {
  height: 300px;
  width: 300px;
  border-radius: 100%;
  padding: 15%;
  object-fit: cover;
}

.photo-plus {
  background: #ffff;
  border-radius: 50%;
  border: 1px solid black;
  width: 60px;
  height: 60px;
  margin-top: -90px;
  margin-left: 80px;
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.36);
}

.photo-icon {
  width: 38px !important;
  height: 38px !important;
  padding: 3px !important;
  margin-top: 3px;
  object-fit: cover;
}

.plus-icon {
  width: 25px !important;
  height: 24px !important;
  padding: 3px !important;
  margin-top: -5px;
}

#inputGroupFile01 {
  display: none !important;
}

.file-btn {
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(204, 0, 51) 0%,
    rgb(146, 0, 36) 100%
  );
  border: none !important;
  border-radius: 10px;
  padding: 8px 85px 8px 85px;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
}

.file-link {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
  text-decoration: none !important;
  background-color: transparent !important;
  border: none !important;
}

.upload-photos-btns {
  margin-top: 40% !important;
}

.upload-photo-header {
  margin-top: 15%;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
}

.upload-photo-text {
  margin-top: 3%;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 15%;
}

.choose-manager-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 10% !important;
}

.manager-search-icon {
  width: 13px !important;
  height: 13px !important;
  float: right;
  margin-top: -30px !important;
  margin-right: 10px;
}

.next-step-btn {
  margin-top: 60% !important;
  margin-bottom: 10% !important;
}

.first-sc-col {
  margin-left: 10%;
  margin-right: 10%;
}

.first-sc-header {
  font-weight: 600;
  font-size: 24px;
  margin-top: 175px;
}

.sc-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 5%;
}

.sc-input {
  height: 160px;
  padding: 4px 8px 4px 8px;
  box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.19);
  background: #ffffff;
  color: #c0c0c0;
  border-color: #767676;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px 3px 3px 3px;
  margin-bottom: 5%;
}

.manager-btn {
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 0%,
    rgb(89, 29, 119) 100%
  ) !important;
}

.js-copytextarea {
  color: transparent !important;
  background: transparent !important;
  border: none !important;
  resize: none !important;
}

.js-copytextarea:focus {
  color: transparent !important;
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.js-copytextarea::selection {
  color: transparent !important;
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.js-textareacopybtn {
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 0%,
    rgb(89, 29, 119) 100%
  ) !important;
  border-radius: 5px;
  padding: 10px 15px 10px 15px !important;
  color: white;
  font-weight: 700;
}

.js-textareacopybtn:hover {
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 0%,
    rgb(89, 29, 119) 100%
  ) !important;
  border-radius: 5px;
  padding: 10px 15px 10px 15px !important;
  color: rgb(177, 247, 177);
  font-weight: 700;
}

.js-textareacopybtn:focus {
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 0%,
    rgb(89, 29, 119) 100%
  ) !important;
  border-radius: 5px;
  padding: 10px 15px 10px 15px !important;
  color: rgb(38, 211, 38);
  font-weight: 700;
}

.alert {
  margin-top: -70px !important;
  margin-bottom: -150px !important;
}
