.policy-text {
  font-size: 14px !important;
  text-align: left;
}

.modal-title-left {
  font-size: 19px !important;
  font-weight: bold !important;
  text-align: left;
}

audio::-webkit-media-controls-panel {
  background: linear-gradient(
    to right,
    rgb(248, 248, 248) 46%,
    rgb(197, 197, 197) 100%
  );
}

#helpModalBtn {
  background: #9933cc;
  width: 30px;
  height: 30px;
  font-size: 18px;
  padding-bottom: 2px;
  text-align: center;
  color: white;
  border-radius: 100%;
}

.policyterms {
  color: #9933cc;
  padding: 0px;
}

.policyterms:hover {
  font-size: 14px;
}

#helpModalBtn:hover {
  background: #19a5e0;
}

.landing-page {
  overflow-x: hidden;
}

.landing-image-row {
  padding: 8px 8px 8px 8px;
  background: linear-gradient(
    to right,
    rgb(248, 248, 248) 46%,
    rgb(197, 197, 197) 100%
  );
  justify-content: center;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.landing-image {
  width: 336px;
  height: 338px;
}

.landing-row {
  text-align: center;
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 0px 4px rgba(170, 170, 170, 0.75);
  background: #ffffff !important;
  border-color: #ffffff;
  border-width: 1px;
  border-style: solid;
}

.landing-ul {
  list-style-type: none !important;
  padding-left: 0px !important;
}

.landing-logo {
  margin-top: 5%;
  width: 325px;
  height: auto;
  margin-bottom: 15%;
}

.landing-header {
  color: var(--dark-blue);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 5%;
}

.landing-text {
  color: #232323;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  padding-left: 15% !important;
  padding-right: 15% !important;
  margin-bottom: 10%;
}

.login-btn {
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(204, 0, 51) 0%,
    rgb(146, 0, 36) 100%
  );
  border: none !important;
  border-radius: 10px;
  padding: 15px 120px 15px 120px;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
}

.login-link {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
  text-decoration: none !important;
}

.signup-btn {
  background: transparent;
  border: red !important;
  padding: 15px 120px 15px 120px;
}

.signup-link {
  color: #9c29b7 !important;
  font-weight: 400;
  font-size: 17px;
  text-decoration: none !important;
}

.disclaimer-text {
  margin-left: 15%;
  margin-right: 10%;
  color: #7a7a7a;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  text-align: left;
}

@media screen and (min-width: 700px) {
  .landing-logo {
    margin-bottom: 4% !important;
  }
  .landing-header {
    margin-bottom: 3% !important;
  }
  .landing-text {
    margin-bottom: 5%;
  }
  .disclaimer-text {
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
  }
  .login-logo {
    margin-top: 10% !important;
    margin-bottom: 5% !important;
  }
}
