.edit-col {
  max-width: 100%;
  justify-content: center;
  margin: auto;
}

.edit-header {
  font-weight: 600;
  font-size: 24px;
  margin-top: -30px;
}

.edit-profile-img {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  object-fit: cover;
}

.new-password {
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 0%,
    rgb(89, 29, 119) 100%
  ) !important;
  border-radius: 5px;
  padding: 10px 15px 10px 15px !important;
  color: white;
  font-weight: 700;
}

.cursor-hover {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .edit-col {
    margin-left: 5% !important;
    margin-right: 0% !important;
  }
}

/* Edit success */

.edit-success-icon {
  margin-top: 65px;
  height: 14px;
}

.edit-sc-header {
  font-weight: 600;
  font-size: 24px;
}

@media (max-width: 600px) {
  .edit-sc-header {
    font-weight: 600;
    font-size: 24px;
    margin-top: 0px;
  }
  .space-below {
    margin-top: 175px;
  }
  .space-below-2 {
    margin-top: 175px;
    margin-bottom: 10px !important;
  }
}

.space-below {
  margin-bottom: 40px !important;
}

.space-below-2 {
  margin-bottom: 10px !important;
}

.sc-icon-edit {
  font-size: 30px;
  position: relative;
  top: -15px;
  left: 15px;
  color: rgb(190, 33, 54);
}

.clear-space {
  height: 0px !important;
  text-align: right;
}
