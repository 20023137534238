.directory-page {
  margin-left: 5%;
  margin-right: 5%;
  background-color: #f9f9f9;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
}

.directory-title {
  font-weight: 400;
  font-size: 18px;
  padding: 3%;
}

.search-input {
  width: 80% !important;
}

.search-directory-row {
  padding-left: 5%;
  padding-right: 5%;
}

.directory-box {
  padding: 2% 2% 2% 3%;
  background: #f9f9f9;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-bottom: 2px solid #ebeaea;
}

.directory-letter {
  font-weight: bold;
  font-size: 18px;
}

.directory-wrapper {
  background-color: #f9f9f9;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .directory-page {
    margin-top: 30%;
  }
}

@media screen and (min-width: 600px) {
  .directory-page {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.contact {
  margin-bottom: 3%;
  margin-left: 1%;
  margin-right: 1%;
}

.contact-btn {
  padding: 0px 0px 0px 0px !important;
  background-color: #fff;
  border-color: #aaaaaa;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px 5px 5px 5px;
  height: 25px;
  width: 25px;
  background-color: #fff !important;
}

.contact-icon {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  object-fit: cover;
}

.mcontact-icon {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  border: 2px #aaaaaa;
  object-fit: cover;
}

.small-p {
  font-size: 10px;
  color: grey;
  text-align: center;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.contact-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  padding: 0px;
  margin: 7px;
}

.contact-btns {
  right: 0;
  bottom:0;
  position: fixed;
  margin-bottom: 10px;
  width: 20px;
  margin-right: 10px;
  line-height: 1.2;

  @media (max-height:600px), (max-width:450px){
    display:none
  }
}

.letter-btn {
  background: transparent;
  border: none !important;
  font-weight: bold;
  font-size: 14px;
}

#search-addon {
  background-color: #fff;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid #e6e6e6 !important;
}
.search-icon {
  padding-top: 10px !important;
  width: 33px !important;
  height: 33px !important;
}

.search-bar {
  border-radius: 5px 0px 0px 5px !important;
  border: 1px solid #e6e6e6 !important;
  border-right: none !important;
}

.search-row {
  margin-top: 10px;
  margin-bottom: 15px;
}

.hide-letter {
  display: none;
  height: 0 !important;
}

.hide-letter-row {
  height: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  visibility: hidden !important;
}
