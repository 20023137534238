.closeBtn {
  background: var(--primary-color);
  color: white;
  border: none !important;
  font-size: 22px;
  width: 32px;
  padding: 0px !important;
  border-radius: 10px !important;
}
@media screen and (max-width: 600px) {
  .feedback-page {
    padding-left: 5% !important;
    padding-right: 0% !important;
  }
  .rating-feedback {
    font-size: 12px !important;
  }
}
.submit-col {
  text-align: right !important;
}
.feed-textbox {
  resize: none;
}
.feedback-res {
  resize: none;
  background-color: #e9ecef !important;
  opacity: 1;
}
.feed-m-row {
  padding: 0px !important;
  margin: 0px !important;
}

.feed-message {
  padding: 0px 0px 0px 10px !important;
  margin: -5px 0px -10px 0px !important;
  color: rgba(123, 123, 123, 0.71);
  font-size: 13px !important;
}
.newShoutImg {
  width: 24px;
  border-radius: 100%;
  object-fit: cover;
}

.css-1s2u09g-control {
  border: none !important;
  background-color: transparent !important;
}

.css-1pahdxg-control {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1wy0on6 {
  display: none !important;
}

.css-319lph-ValueContainer {
  border-bottom: 2px solid #dcdcdc;
}

.css-1d8n9bt {
  border-bottom: 2px solid #dcdcdc;
}

.css-6j8wv5-Input {
  margin-bottom: -4px !important;
}

.css-qc6sy-singleValue {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}
.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.confetti-row {
  margin-top: -100px;
  margin-bottom: 100px;
}

.closeBtn span {
  font-weight: 300 !important;
}

.btn-delete {
  color: red;
  float: left !important;
}

.modal-footer-2 {
  justify-content: flex-start !important;
}

.modal {
  z-index: 9999 !important;
}

.main-action {
  margin-left: 15px;
  margin-right: 15px;
}

.sub-action {
  margin-left: 15px;
  margin-right: 15px;
}

.modal-content {
  box-shadow: 0px 5px 5px rgba(146, 146, 146, 0.51);
  background: #f9f9f9;
  border: none !important;
  z-index: 2;
  padding: 2px 5px 2px 5px;
}

.modal-header {
  border-bottom: 0 none;
  padding-bottom: 0px !important;
}

.modal-title {
  font-weight: 800;
  font-size: 18px;
}

.modal-footer {
  border-top: 0 none;
}

.modal-content .form-group {
  margin-bottom: 10px;
}

.action-label {
  font-weight: 600;
  font-size: 14px;
}

.action-input {
  font-weight: 400;
  font-size: 14px;
  background: transparent;
  border: none;
  border-bottom: 2px solid #dcdcdc;
  border-radius: 0px !important;
}

.shout-out-input {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  background: transparent !important;
  border: none;
  border: 2px solid #dcdcdc;
  border-radius: 5px !important;
  height: 150px !important;
  resize: none !important;
}

.shout-out-input:focus {
  box-shadow: 0px 0px !important;
  border-color: #dcdcdc;
}

.action-select {
  font-weight: 400;
  font-size: 14px;
  background-color: #f9f9f9;
  border: none;
  border-bottom: 2px solid #dcdcdc;
  border-radius: 0px !important;
}

.action-input:focus {
  box-shadow: 0px 0px !important;
  border-color: #dcdcdc;
}

.action-select:focus {
  box-shadow: 0px 0px !important;
  border-color: #dcdcdc;
}

.action-header {
  font-weight: 600;
  font-size: 14px;
}

.action-icon {
  border-bottom: 2px solid #dcdcdc;
  border-radius: 0px !important;
  text-align: left !important;
}

.action-i {
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 16px;
  margin-left: -5px;
}

.action-icon-col {
  padding: 0px !important;
  margin: 0px !important;
}

.action-input-col {
  padding-right: 0px !important;
}

select option {
  color: #ffa834 !important;
}

.p-green {
  color: #72bb53 !important;
}

.p-blue {
  color: #1464f6;
}

.p-red {
  color: #e61610;
}

.star-select {
  font-size: 20px;
}

.disabled-input {
  background: transparent !important;
}

.my-actions-header {
  margin-bottom: 15px !important;
  margin-top: 20px !important;
}

.action-zindex {
  z-index: 1 !important;
}

.btn-main-action {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  z-index: 20 !important;
}

.btn-main-action:focus {
  border: none !important;
  box-shadow: none !important;
}

.action-p {
  margin-bottom: 0px;
}

.actions-display {
  padding-bottom: 20px !important;
}

.action-card-body {
  border: none !important;
  padding-left: 50px;
  padding-top: 8px;
}

.sort-btn {
  width: 20px !important;
  transform: rotate(0deg) !important;
}

.collapsed .sort-btn {
  transform: rotate(-90deg) !important;
}

.action-sort {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  vertical-align: middle;
  margin-bottom: 0px;
  overflow: hidden;
  max-width: 26ch;
  text-overflow: ellipsis;
}

.the-sub-action {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  border: none !important;
  overflow: hidden;
  max-width: 30ch;
  text-overflow: ellipsis;
}

.the-sub-action .pencil-icon {
  margin-right: 7px;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
  white-space: nowrap;
}

.inside-action {
  margin-top: 5px;
  margin-left: -25px;
}

.big-switch {
  margin-left: 37px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 0px;
  bottom: -1px;
  background: #cc0033;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  color: #72bb53;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  background-color: #72bb53;
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.disabled-group {
  color: gray !important;
}

.disabled-group select {
  color: gray !important;
  background: transparent !important;
}

.disabled-group input {
  color: gray !important;
  background: transparent !important;
}

.disabled-group p {
  color: gray !important;
  background: transparent !important;
}

.disabled-input select {
  color: gray !important;
  background: transparent !important;
}

.disabled-input {
  color: gray !important;
  background: transparent !important;
}

.priority-icon-low {
  color: #55aa00;
}

.priority-icon-medium {
  color: #ffe100;
}

.priority-icon-high {
  color: #ff6e00;
}

.priority-icon-critical {
  color: #e61610;
}

.slider-row {
  margin-top: -20px;
}

.top-switch {
  margin-top: -20px;
  margin-left: 25px;
}

.hidden-group {
  display: none;
}

.res-modal {
  z-index: 10000 !important;
}

.res-content {
  padding-top: 9% !important;
  padding-bottom: 9% !important;
  margin-left: -5px;
  margin-right: 5px;
}
