@media screen and (max-width: 600px) {
  .edit-profile {
    padding-top: 150px;
  }
}

.my-profile-img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

/* Profile Info */

.prof-info-card {
  padding: 0px !important;
  margin: 0px !important;
}

.prof-info-box,
.small-info-box {
  background: #f6f6f6;
  padding: 3% 3% 3% 3%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  margin-bottom: 20px;
}

.prof-info-box {
  margin-top: -90px;
  word-wrap: break-word;
}

.prof-info-hr {
  border-bottom: 2px solid #b4b4b4;
  margin-left: 3%;
  width: 93%;
  margin-top: 0px !important;
  margin-bottom: 3% !important;
}

.prof-info-header {
  font-weight: 900;
  font-size: 18px;
  margin-top: 100px;
  margin-bottom: 7%;
}

.prof-info-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3% !important;
}

.prof-info-text {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3% !important;
}

/* Success Criteria */

.success-info-header {
  font-weight: 900;
  font-size: 18px;
  margin-top: 60px;
  margin-bottom: 3%;
}

.success-info-icon {
  /* margin-top: 60px; */
  font-size: 25px;
}

.success-info-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 1% !important;
}

.success-info-text {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px !important;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  max-height: 0; /* fallback */
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.show-more-success {
  max-height: none !important; /* fallback */
  -webkit-line-clamp: 10; /* number of lines to show */
  -webkit-box-orient: vertical;
}

svg#up-1,
svg#down-1 {
  cursor: pointer;
}

.hidden {
  display: none;
}

.read-more {
  font-weight: 600;
  font-size: 12px;
  color: black !important;
  background-color: #b4b4b4;
  width: 80px;
  padding: 0px;
  padding-left: 2px;
  margin-left: 2.5%;
  margin-bottom: 2%;
}

@media screen and (min-width: 765px) {
  .success-info-box {
    padding-left: 5%;
  }
}

/* Shout Outs */

.shout-card {
  padding: 4% 4% 4% 4%;
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 0%,
    rgb(89, 29, 119) 100%
  );
  border-radius: 15px;
  margin-top: 1%;
}

.shout-modal {
  padding: 4% 4% 4% 4%;
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 0%,
    rgb(89, 29, 119) 100%
  ) !important;
  border-radius: 15px;
  margin-top: 2%;
}

.shout-header {
  font-weight: 900;
  font-size: 18px;
  color: white;
}

.shout-row {
  margin-top: 3%;
  margin-bottom: 3%;
}

.shout-img {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  object-fit: cover;
}

.shout-text {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  margin-top: 0px;
  margin-bottom: 0px;
}

.likes-num {
  color: #ffffff !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

.likes {
  margin-left: 10px;
}

/* NoteCard */

.note-card {
  padding: 4% 4% 4% 4%;
  background: rgb(244, 239, 179);
  border-radius: 5px;
  margin-top: 2.5%;
  height: 100%;
}

.lines {
  border-left: 1px solid #ffaa9f;
  border-right: 1px solid #ffaa9f;
  width: 2px;
  float: left;
  height: 90%;
  margin-left: 30px;
}
.note-list {
  color: #555;
  font-size: 12px;
  padding: 0 !important;
  font-family: courier, monospace;
  border: 1px solid #dedede;
  margin-bottom: 0px;
}
.note-list li {
  list-style: none;
  border-bottom: 1px dotted #ccc;
  text-indent: 25px;
  height: auto;
  padding: 10px;
  text-transform: capitalize;
}
.note-list li:hover {
  background-color: #f0f0f0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.note-form {
  height: 20px;
}

.note-input {
  background: transparent;
  border: none;
  font-size: 13px;
  color: red !important;
}

.note-list .form-inline {
  padding-top: 0px !important;
}

.row-2 {
  --bs-gutter-x: none !important;
}

.note-input:focus-visible {
  outline: none !important;
}

.notes-header {
  font-weight: 900;
  font-size: 18px;
  color: black;
}

.edit-prof-icon {
  margin-top: 100px;
  margin-left: 20px;
}

/* Momentum */

.momentum {
  background: linear-gradient(
    to right,
    rgb(255, 191, 0) 0%,
    rgb(255, 191, 0) 100%
  );
  border-radius: 50%;
  width: 27px;
  height: 27px;
  text-align: center;
  padding-left: 12px;
  padding-top: 2px;
  border: 1px solid #e6e6e6 !important;
}

.momentum-icon {
  font-size: 22px;
}

.momentum-up {
  transform: rotate(-45deg);
  transform-origin: left;
  transition: ease 2s;
}

.momentum-down {
  transform: rotate(45deg);
  transform-origin: left;
}

.red-momentum {
  background: linear-gradient(to right, red 0%, rgb(210, 34, 45) 100%);
}

.green-momentum {
  background: linear-gradient(
    to right,
    rgb(35, 136, 35) 0%,
    rgb(35, 136, 35) 100%
  ) !important;
}

/* survey */

.survey-row {
  margin-top: 5%;
  margin-bottom: 5%;
}
