:root {
  --primary-color: #cc0133;
  --secondary-color: #34ccff;
  --tertiary-color: #9833cc;
  --dark-blue: #273c5f;
  --nearly-black: #131e2f;
  --font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--dark-blue);
}

p {
  color: var(--nearly-black);
}

a:hover {
  color: var(--tertiary-color);
}
