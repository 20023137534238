.pricing-header {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 5%;
  margin-top: 5%;
}

.dash-inner {
  margin-top: 170px;
  padding: 5%;
}

.company-dash {
  padding-left: 5%;
  padding-right: 5%;
  margin-top: -200px;
}

@media screen and (max-width: 600px) {
  .company-dash {
    padding-left: 1%;
    padding-right: 1%;
    margin-top: -50px !important;
  }
}

.company-btn {
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(204, 0, 51) 0%,
    rgb(146, 0, 36) 100%
  );
  border: none !important;
  border-radius: 10px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
}

.package-btn {
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(114, 187, 83) 0%,
    rgb(69, 120, 48) 100%
  );
  border: none !important;
  border-radius: 10px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
  margin-top: 15%;
}

.payment-btn {
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(114, 187, 83) 0%,
    rgb(69, 120, 48) 100%
  );
  border: none !important;
  border-radius: 10px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
  margin-top: 5%;
}

.package-button {
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 0px 6px rgba(122, 122, 122, 0.28);
  background: #ffffff;
  border: none !important;
  border-radius: 10px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
  margin-top: 5%;
}

.package-link {
  color: #629c44;
  text-decoration: none !important;
  font-weight: 400;
  font-size: 17px;
}

.company-header {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 7% !important;
}

.package-card {
  width: 261px !important;
  padding: 5% 5% 5% 5%;
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 51%,
    rgb(84, 24, 115) 100%
  );
  color: #ffffff !important;
  border-radius: 15px;
}

.enterprise-card {
  width: 261px !important;
  padding: 5% 5% 5% 5%;
  background: linear-gradient(
    to right,
    rgb(51, 204, 255) 0%,
    rgb(22, 102, 129) 100%
  );
  color: #ffffff !important;
  border-radius: 15px;
}

.package-header {
  font-weight: 900;
  font-size: 36px;
}

.package-li {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
}

.form-check {
  margin-right: 0px !important;
}

.price-strong {
  font-weight: 900;
  font-size: 48px;
  line-height: 1.3;
}

.package-month {
  font-weight: 900;
  font-size: 14px;
  line-height: 1.3;
}

.package-billed {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  margin-top: -15px;
}

.myRadios {
  margin-top: 60% !important;
  margin-left: -30px !important;
}

.payment-disclaimer {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  margin-top: 5%;
}

.add-users-logo {
  width: 180px;
  height: 30px;
  margin-top: 5%;
  margin-left: -5%;
}

.download-box {
  padding: 4% 3% 0% 3%;
  border-radius: 15px;
  background: linear-gradient(
    to right,
    rgb(51, 204, 255) 13%,
    rgb(22, 102, 129) 100%
  );
  margin-bottom: 5%;
}

.upload-box {
  padding: 4% 3% 0% 3%;
  border-radius: 15px;
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 51%,
    rgb(84, 24, 115) 100%
  );
}

.box-text {
  color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3;
}

.text-col {
  padding: 0px !important;
}

.file-csv-light {
  width: 29px !important;
  height: 39px !important;
  margin-left: -8px !important;
  margin-top: 8px !important;
  color: #ffffff !important;
}

.file-dark {
  width: 29px !important;
  height: 39px !important;
  margin-left: -8px !important;
  color: black !important;
}

.add-text {
  font-size: 14px;
  line-height: 1.3;
}

.add-header {
  font-weight: 600;
  font-size: 24px;
  margin-top: 10%;
}

.box-dark {
  color: black;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3;
}

.adduser-table {
  height: 200px;
  overflow: scroll;
}

.adduser-block {
  margin-top: 10%;
}

.adduser-col {
  border: 1px solid #e6e6e6 !important;
}

.icon-btn {
  background: transparent;
  float: right;
  border: 1px solid #e6e6e6 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.select-all {
  background: transparent;
  border: 1px solid #e6e6e6 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.sort-down-icon {
  height: 20px;
  width: 40px;
  padding: 0px !important;
}

.next-btn {
  margin-top: 10%;
  padding: 8px 8px 8px 8px;
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(204, 0, 51) 0%,
    rgb(146, 0, 36) 100%
  );
  border: none !important;
  border-radius: 10px;
  padding: 15px 0px 15px 0px;
  width: 100%;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 17px;
}

.edit-company-tier {
  text-align: right;
}

.company-directory {
  font-size: 26px;
}

.no-decor {
  text-decoration: none !important;
}

.make-div-right {
  float: right !important;
}

.company-focus-page {
  padding-left: 10%;
  padding-right: 10%;
}

.pre-line {
  white-space: pre-line;
}

.add-r-box {
  padding: 8%;
  padding: 16px 8px 0px 8px;
  box-shadow: 0px 5px 10px rgb(248 95 106 / 23%);
  background: linear-gradient(
    to right,
    rgb(51, 204, 255) 0%,
    rgb(22, 102, 129) 100%
  );
  border-radius: 8px;
  margin-top: 20px;
}

.add-r-box-red {
  background: linear-gradient(
    to right,
    rgb(184, 177, 178) 0%,
    rgb(190, 33, 54) 100%
  ) !important;
}
