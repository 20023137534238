.mentor-img {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  object-fit: cover;
}

.action-btn-mentor {
  margin-top: -10px !important;
}

.tiny-text {
  color: #757575;
  font-size: 12px;
  text-align: center;
}

.convo-btn {
  float: right;
}

.convo-se {
  float: right;
  margin-top: 3.5px;
  background-color: red !important;
  margin-left: 5px;
}
