/* Team Stats */

#apexchartsapexchartxmanagerxteams .apexcharts-legend-series {
  margin-right: 15% !important;
  margin-left: 15% !important;
}

.apexcharts-legend-text {
  font-weight: 400 !important;
  font-size: 14px !important;
}

@media (max-width: 700px) {
  #apexchartsapexchartxmanagerxteams .apexcharts-legend-series {
    margin-right: 8% !important;
    margin-left: 8% !important;
  }
}

/* Action Stats */

.apexcharts-text tspan {
  font-weight: 400 !important;
  font-size: 14px !important;
}

#SvgjsSvg1108 {
  margin-top: -20px !important;
}

/* Rating */

.rating-page {
  margin-top: 5%;
  margin-bottom: 5%;
}

.rating-text {
  font-weight: 400;
  font-size: 14px;
  padding-left: 12%;
  margin: 5% 0;
}

.rating-stars {
  font-size: 28px;
}

.newRateImg {
  border-radius: 100%;
  width: 60px;
  margin-right: 7px;
}

.purple {
  color: rgb(201, 25, 201) !important;
}

.purple:hover {
  color: rgb(79, 137, 209) !important;
}

/* Q Momentum */

#apexchartsapexchartxqxmomentum .apexcharts-legend {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.apexcharts-tooltip {
  color: #000000;
  padding: 2% !important;
  margin-top: 25%;
  font-weight: bold !important;
}

@media screen and (max-width: 600px) {
  .apexcharts-tooltip {
    color: #000000;
    padding: 4% !important;
    margin-top: 75%;
  }
}

.white-bg {
  background: transparent;
}

.grey-bg {
  background: #e6e6e6;
}

.centered-stars {
  margin-top: 5px;
  font-size: 24px;
  margin-bottom: -5px;
}

@media screen and (max-width: 767px) {
  .centered-name {
    padding-top: 5px !important;
    margin-bottom: 5px;
  }
}

.cenetered-momemtum {
  margin-top: 7px;
  margin-left: 60px;
  margin-bottom: -10px;
  text-align: center !important;
}
