.survey-col {
  background: rgb(115, 186, 245);
  padding: 1%;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.small,
small {
  font-size: 1.2em;
}
