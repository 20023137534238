@media screen and (max-width: 600px) {
  .dash-container {
    margin-top: 150px;
  }
  .sc-page {
    margin-top: 150px;
  }
  .actions-page {
    margin-top: 150px;
  }
}

/* Employee Dash */

.all-time {
  font-size: 10px;
}

.dash-container {
  margin-left: 5%;
  margin-right: 5%;
}

.dash-header {
  font-weight: 600;
  font-size: 24px;
}

.dash-row {
  margin-top: 20px;
}

.dash-col {
  padding-right: 5px !important;
}

.dash-col-2 {
  padding-left: 5px !important;
}

.actions-box {
  border-radius: 10px;
  padding: 3% 3% 3% 3%;
  background: linear-gradient(
    to right,
    rgb(51, 204, 255) 0%,
    rgb(22, 102, 129) 100%
  );
}

.past-box {
  border-radius: 10px;
  padding: 3% 3% 3% 3%;
  box-shadow: 0px 5px 10px rgba(248, 95, 106, 0.23);
  background: linear-gradient(
    to right,
    rgb(204, 0, 51) 0%,
    rgb(146, 0, 36) 100%
  );
}

.action-text {
  color: #ffffff;
  font-weight: 900;
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.action-num {
  line-height: 1;
  color: #ffffff;
  font-weight: 900;
  font-size: 30px;
  margin-bottom: 5px;
}

.action-status-row {
  padding: 2.5%;
  padding-bottom: 0px;
}

.action-status {
  padding: 3% 3% 3% 3%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  border-color: #c0c0c0;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px 10px 10px 10px;
}

.action-status-header {
  margin-bottom: 0px;
}

.action-small {
  padding: 3% 3% 3% 3%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  border-color: #c0c0c0;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px 10px 10px 10px;
}

.rating-stars {
  font-size: 24px;
  margin-bottom: -4px;
  margin-top: -4px;
}

.se-num {
  background-color: #9c29b7;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 100%;
  width: 27px;
  height: 27px;
  padding-top: 2px;
  margin-left: 5px;
}

.se-num-important {
  background-color: #9c29b7;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border-radius: 50% !important;
  width: 27px !important;
  height: 27px !important;
  padding-top: 2px !important;
}

.progress {
  height: 30px !important;
}

.pie-chart {
  margin-top: -20px;
  padding: 0% 10% 0% 10%;
  margin-bottom: 10px;
  height: 300px;
}

.pie-chart text {
  font-size: 8px;
  font-weight: 400;
}

.completed-text {
  color: #388c44;
  font-weight: 400;
  font-size: 14px;
  margin-top: -20px;
}

.not-completed-text {
  color: #1d4b24;
  font-weight: 400;
  font-size: 14px;
  margin-top: -20px;
  margin-bottom: 20px;
}

@media screen and (min-width: 600px) {
  .dash-container {
    margin-left: 15%;
    margin-right: 15%;
  }
  .sc-page {
    margin-left: 10%;
    margin-right: 10%;
  }
}

/* Employee Checkin */

.sc-page {
  padding-left: 5%;
  padding-right: 5%;
}

.sc-header {
  font-weight: 600;
  font-size: 24px;
}

.sc-question {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 1%;
}

.sc-text-input {
  height: 160px;
  padding: 4px 8px 4px 8px;
  box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.19);
  background: #ffffff;
  color: #c0c0c0;
  border-color: #767676;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px 3px 3px 3px;
  margin-bottom: 1%;
}

.past-icon {
  margin-right: 1%;
}

.view-past {
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  margin-bottom: 20px;
}

.view-past:hover {
  color: #9933cc;
}

.hide-past {
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  margin-bottom: 20px;
  color: #9933cc;
}

.hide-past:hover {
  color: black;
}

.hide-past {
  display: none;
}

.show-past-btn {
  display: inline-block;
}

.hide-past-btn {
  display: none;
}

.view-past-q {
  display: none;
}

.show-past-q {
  display: inline-block;
}

.past-q-header {
  color: #9933cc;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 5px;
}

.past-q-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
}
.addcheck-btn {
  background-color: #9933cc;
}

.addcheck-btn:hover {
  color: #7d1dad;
}

/* Actions */

.main-pencil {
  margin-bottom: 4px;
  margin-left: 10px;
}

.actions-page {
  padding-left: 5%;
  padding-right: 5%;
}

.actions-header {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 8px;
}

.action-btn {
  font-weight: 400;
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 4px;
  text-align: left;
  color: #0d6efd;
}

.action-btn:hover {
  color: #9933cc;
}

.priority-key {
  box-shadow: 0px 5px 5px rgba(146, 146, 146, 0.24);
  background: #9933cc;
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px 10px 10px 10px;
  bottom: 100px;
  width: 90%;
  margin-left: 0%;
  padding: 10px 20px 10px 20px;
  color: #ffffff !important;
  z-index: 30;
}

.priority-header {
  font-weight: 800;
  font-size: 18px;
  text-align: center;
}

.priority-text {
  font-weight: 400;
  font-size: 16px;
}

.priority-icon-yellow {
  color: #ffe100;
}

.priority-icon-green {
  color: #55aa00;
}

.priority-icon-blue {
  color: #1900d5;
}

.priority-icon-orange {
  color: #ff6e00;
}

.priority-icon-red {
  color: #e61610;
}

@media (min-width: 600px) {
  .priority-key {
    width: 60%;
    margin: 0 2%;
    bottom: 0px;
  }
}

@media screen and (max-width: 370px) {
  .priority-text {
    font-weight: 400;
    font-size: 14px !important;
  }
}

.btn-checkin a {
  color: black !important;
  text-decoration: none !important;
  font-size: 14px !important;
}

.next-checkin {
  padding: 3%;
  font-size: 16px;
}

.next-checkin-box {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  background: #f9f9f9;
  border-color: #c0c0c0;
  border-width: 1px;
  border-radius: 10px;
}
@media screen and (max-width: 600px) {
  .back-directory {
    margin-top: 120px;
    margin-bottom: -130px;
  }
  .reply-icon {
    margin-top: 1%;
    margin-left: -10px;
  }
}

.back-check {
  margin-bottom: 10px;
  margin-left: -15px;
}

.return-icon {
  font-size: 20px;
}

.reply-icon {
  font-size: 14px;
  background-color: white;
  border-radius: 10px;
  padding: 2px 6px 2px 6px !important;
  color: #e61610;
  font-weight: bold;
}
