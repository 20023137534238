.login-page {
  overflow-x: hidden;
}

.login-header {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 10%;
  margin-top: 5%;
}

.form-row {
  width: 300px;
}

.success-label {
  margin-bottom: 2% !important;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

.success-input {
  border-radius: 0px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-bottom: 3%;
  padding-left: 0px !important;
}

.login-btn-row {
  margin-top: 20%;
}

.login-logo {
  margin-top: 10%;
  margin-bottom: 15%;
  width: 275px;
  height: auto;
}

.forgot-btn {
  background: transparent;
  border: none !important;
  padding: 15px 0px 15px 0px;
}

.forgot-link {
  color: #989eb1 !important;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none !important;
}

.login-signup-btn {
  background: transparent;
  border: none !important;
  text-align: right;
  margin-top: 20%;
}

.login-signup-link {
  text-decoration: none !important;
  color: #9c29b7;
  font-weight: 600;
  font-size: 16px;
}

.errors-p {
  font-weight: 400;
  color: red;
  font-size: 12px;
  margin-top: -8px;
}

.errors-p2 {
  font-weight: 400;
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
}
