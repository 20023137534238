.shout-outs-employee {
  margin-left: 2%;
  padding-right: 5.5%;
}

.notifs-employee .notif-outside .notif-btn-row .btn-notif {
  margin-left: 3%;
  width: 90px;
}

.shout-outs-employee .notif-outside .col-4 .btn-shout {
  margin-left: 5%;
  width: 90px;
}

@media screen and (min-width: 1000px) {
  .notifs-employee .notif-outside .notif-btn-row .btn-notif {
    margin-left: 1%;
  }
}

.back-btn {
  text-align: left;
  color: rgb(180, 8, 180);
}

.nn {
  font-weight: 400;
  font-size: 18px;
}

.sn {
  font-weight: 400;
  font-size: 14px;
}

.sn-n {
  padding-left: 5%;
}

.sn-s {
  padding-left: 3%;
}

.notif-page-outside {
  padding-left: 5%;
  padding-right: 5%;
}

.notif-title-row {
  font-weight: 100 !important;
  margin-left: 2%;
}

.notif-subtitle-row {
  margin-left: 4%;
  margin-top: 1%;
  font-size: smaller;
  color: rgb(94, 94, 94);
}

.top-card {
  margin-top: 8%;
}

.notif-outside {
  margin-left: 5%;
}

.notif-icon {
  height: 40px !important;
  width: 40px !important;
  border-radius: 100%;
}

.icon-col {
  padding: 0px !important;
}

.notif-text {
  padding-top: 2px;
  font-size: 12px;
  padding-left: 5%;
}

.btn-notif {
  background-color: blueviolet;
  color: white;
  width: 80%;
  height: 30px;
  vertical-align: middle;
  border: none;
  border-radius: 5px;
  margin-top: 3px;
}

.btn-notif-grey {
  background-color: rgb(162, 162, 162) !important;
}

.btn-shout {
  background-color: blueviolet;
  color: white;
  width: 80%;
  height: 30px;
  vertical-align: middle;
  border: none;
  border-radius: 5px;
  margin-top: 3px;
}

.card-box {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  background: #f9f9f9;
  border-color: #c0c0c0;
  border-width: 1px;
  margin-bottom: 5%;
  border-radius: 10px;
}

.box-title {
  font-weight: 900;
  font-size: 15px;
  margin-top: 15px;
}

.engagement-icon {
  color: black !important;
  height: 40px;
  width: 40px !important;
  margin-left: -15px !important;
  border-radius: 100%;
}

.user-icon {
  width: 40px !important;
  height: 40px;
  margin-top: 15px;
  margin-left: -50px;
  border-radius: 100%;
}

.engagement-row-employee {
  padding-left: 30px !important;
}

@media screen and (max-width: 1000px) {
  .card-box {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .box-title {
    margin-left: 6%;
    font-weight: 300 !important;
    font-size: smaller !important;
    color: rgb(94, 94, 94);
  }

  .engagement-row {
    margin-left: 5px !important;
    margin-bottom: -30px !important;
  }

  .top-card {
    margin-top: 5px;
  }
}

@media screen and (max-width: 600px) {
  .notif-page-outside {
    margin-top: 35%;
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .notif-btn-row {
    justify-content: flex-end;
  }

  .notif-text {
    padding-top: 2px;
    font-size: 12px;
    padding-left: 5%;
  }

  .shout-outs-employee {
    padding-left: 5.5%;
    padding-right: 4%;
    margin-left: 0% !important;
  }
}

@media screen and (min-width: 640px) {
  .notif-page-outside {
    padding-right: 10%;
  }
}
