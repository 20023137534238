html,
body {
  overflow-x: hidden;
  overflow-y: visible !important;
}

/* AI Button */
.header-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.fixed-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* Add styles to make the button circular */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #8c00ff;
  color: #fff;
  border: none;
  /* Add hover styles */
  cursor: pointer;
  transition: all 0.3s ease;
}

.fixed-btn:hover {
  background-color: #029ef8;
}

/* footer */

.footer {
  position: fixed;
  bottom: 0;
  z-index: 25;
}

@media screen and (max-width: 600px) {
  .footer {
    width: 100%;
    overflow: hidden;
  }
}

@media screen and (min-width: 600px) {
  .footer.nav-foot {
    display: none;
  }
}

/* Header */

.header {
  background: linear-gradient(
    0deg,
    rgba(21, 56, 84, 0) 0%,
    rgba(21, 56, 84, 1) 100%
  );
  height: 135px;
  align-items: flex-start;
}

.header-outside {
  overflow-y: visible !important;
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2 !important;
}

.header-star {
  color: white;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  padding-left: 10%;
}

.header-text {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: 300 !important;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 30px;
  padding-left: 10%;
}

.prof-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-left: -80px;
  margin-top: 15px;
  margin-bottom: -10px;
  object-fit: cover;
}

.sign-out-icon {
  font-size: 25px;
}

.button:hover {
  background-color: white;
  border: 2px solid white;
  border-left: none;
}

/* NavBar Side */

.desktop-icon {
  height: 23px !important;
}

.sidebar {
  left: 0;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.16);
}

.nav-block {
  border-bottom: 1px solid #dfdfdf;
  font-size: 10px;
  padding: 0 15px;
}
.nav-block:hover {
  background-color: white;
  border-bottom: 1px solid white;
  color: var(--dark-blue);
}

.nav-block-col {
  position: fixed;
  background: #f6f6f6;
  bottom: 0;
  top: 0;
  left: 0;
  width: 75px;
  padding: 0;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.16);
  z-index: 2;
}

.icon {
  color: var(--nearly-black);
  margin: 0.8rem auto 0.25rem auto;
}

.icon-text {
  color: black !important;
  margin-bottom: 0.7rem;
}

.sidenav-logo {
  width: 40px;
  padding-bottom: 10px;
}

main.col {
  margin-left: 59px;
}

@media screen and (max-height: 775px) {
  .nav-block-col {
    width: 65px;
  }

  .icon {
    margin: 0.85rem auto 0.75rem auto;
  }

  .icon-text {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .nav-block-col,
  nav#sidebar {
    display: none;
  }

  main.col {
    margin-left: 0;
  }

  .content-outside {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 600px) {
  .content-outside {
    padding-bottom: 100px;
    margin: 20px 65px 0px 0px;
    padding-left: 80px;
  }
}

/* NavBar Mobile */

.nav-block-mobile {
  background: #f6f6f6;
  margin: 0 auto;
  justify-content: center;
}

.mobile-block {
  width: 12.5%;
  border: 0.8px solid #dfdfdf;
  padding: 0;
  margin: 0 auto;
}

.mobile-text {
  font-size: 9px;
  color: black !important;
  text-decoration: none !important;
  margin-bottom: 0.6rem;
}

.no-underline {
  text-decoration: none !important;
}

.icon-mobile {
  margin: 0.8rem 0 0.6rem 0;
  height: 1.3rem;
  width: 1.3rem;
  color: black !important;
}

.loading-logo {
  padding: 20%;
}
