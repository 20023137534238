.m-header {
  font-weight: 300;
  font-size: 20px;
}

.m-subheader {
  font-weight: 900;
  font-size: 25px;
}

.team-stats-header {
  font-weight: 900;
  font-size: 25px;
  margin-bottom: 0px !important;
  margin-top: 30px !important;
}

.ts-header {
  font-weight: 400;
  font-size: 18px;
}

/* Manager Check Ins */

.m-checkin-page {
  padding-left: 10%;
  padding-right: 10%;
}
.addcheck-btn {
  background-color: #9933cc;
}

.addcheck-btn:hover {
  color: #7d1dad;
}

@media screen and (max-width: 600px) {
  .m-checkin-page {
    padding-top: 35%;
  }
  .view-check-page {
    padding-top: 35%;
  }
  .check-alert {
    padding: 3px 3px 3px 3px !important;
    background: #33ccff;
    border-radius: 5px;
    margin-bottom: 1%;
  }
  .alert-text {
    padding: 1% !important;
  }
  .alert-p {
    font-size: 13px !important;
  }
}

.m-check-in-table {
  width: 100%;
}

.eye-icon {
  color: black !important;
}

/* View CheckIns */

.view-check-header {
  font-weight: 600;
  font-size: 24px;
  padding: 0px;
}

.view-check-page {
  padding-left: 10%;
  padding-right: 10%;
}

h1 {
  font-size: 2rem;
  margin: 1.25rem 0;
}

.view-check-page h3,
.view-check-page h4 {
  margin: 1.5rem 0;
}

.check-alert {
  padding: 8px;
  background: #33ccff;
  border-radius: 5px;
  margin: 20px 0;
}

.alert-icon {
  color: white !important;
  margin-right: 5px;
  vertical-align: middle;
  font-size: 23px;
  margin-top: -1px;
}

.alert-text {
  line-height: 1;
  margin-bottom: 0px;
}

.alert-p {
  color: white !important;
  font-weight: 400 !important;
  font-size: 18px;
  vertical-align: middle !important;
}

.view-check-sub {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  padding: 0px !important;
}

.view-check-comment-header {
  padding: 0px !important;
  margin-bottom: 2px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
}

.view-check-comment-header-clicked {
  color: #9933cc !important;
}

.view-check-comment {
  padding: 0px !important;
  color: black !important;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
}

.check-title-row:hover {
  color: #9933cc !important;
}

.reply-box {
  display: none !important;
}

.reply-box-001 {
  display: none;
}

.reply-box-002 {
  display: none;
}

.reply-box-003 {
  display: none;
}

.show-reply-box {
  display: inline !important;
}

.reply-header {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  padding: 0px !important;
  margin-top: 2%;
}

.reply-form-box {
  height: 108px !important;
  margin-bottom: 5px !important;
}

.btn-submit {
  background: #9933cc;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

/* Timeframe btns */

.time-btn-row {
  padding: 0px !important;
  width: 60%;
  margin-left: 2px;
}

@media screen and (max-width: 768px) {
  .time-btn-row {
    width: 100%;
  }
}

.btn-1m {
  width: 100%;
  background: linear-gradient(
    to right,
    rgb(153, 51, 204) 51%,
    rgb(84, 24, 115) 100%
  );
  color: #ffffff;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.btn-3m {
  width: 100%;
  background: linear-gradient(
    to right,
    rgb(51, 204, 255) 0%,
    rgb(22, 102, 129) 100%
  );
  color: #ffffff;
  border-radius: 0px !important;
}

.btn-1y {
  width: 100%;
  background: linear-gradient(
    to right,
    rgb(204, 0, 51) 0%,
    rgb(146, 0, 36) 100%
  );
  color: #ffffff;
  border-radius: 0px !important;
}

.btn-A {
  width: 100%;
  background: linear-gradient(
    to right,
    rgb(204, 190, 194) 0%,
    rgb(150, 121, 127) 100%
  );
  color: #ffffff;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
